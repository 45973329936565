"use client";

export default function GlobalError({
  error,
}: {
  error: Error;
}) {
  return (
    <html>
      <head>
        <title>SellRaze</title>
      </head>
      <body>
        <p>{error.name}</p>
        <p>{error.message}</p>
      </body>
    </html>
  )
}
